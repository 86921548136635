import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { memo } from 'react';
import { flex } from '~/modules/AppLayout/FlexGridCss';
import { useSoundStore } from '~/modules/SDK/sound/useSoundStore';
import { clas } from '~/utils/clas';
const classes = {
    Root: styled.div `
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
  `,
    Text: styled.div ``,
    ButtonGroup: styled.div `
    ${flex.h.allCenter};
  `,
    Button: styled.div `
    ${flex.h.allCenter};
    width: 50px;
    height: 25px;
    border-radius: 0;
    cursor: pointer;
    font-size: 14px;
    color: #eeeeee;
    background-color: #5a7685;
    border: 1px solid #666666;
    border-right: none;
    &:hover {
      background-color: #777777;
    }
    transition: 0.3s;
  `,
    ButtonOfStart: styled.div ``,
    ButtonOfStartOfActive: styled.div `
    background-color: #34bf49;

    &:hover {
      background-color: #2fcd47;
    }
  `,
    ButtonOfEnd: styled.div ``,
    ButtonOfEndOfActive: styled.div ``,
};
const defaultPresetCSS = css `
  ${classes.ButtonOfStart} {
    border-radius: 5px 0px 0px 5px;
  }

  ${classes.ButtonOfEnd} {
    border-radius: 0px 5px 5px 0px;
  }

  ${classes.ButtonOfStartOfActive} {
    background-color: #34bf49;

    &:hover {
      background-color: #2fcd47;
    }
  }

  ${classes.ButtonOfEndOfActive} {
    background-color: #e04242;

    &:hover {
      background-color: #ff4c4c;
    }
  }
`;
/**
 * # 聲音開關器
 *
 * ## 在 indicator 內部以 useSoundStore 實作播報聲音即可受控
 */
const SoundSwitch = memo(function SoundSwitch(props) {
    const isActive = useSoundStore(state => state.soundsEnabled);
    return (<classes.Root css={defaultPresetCSS} className={props.className}>
      <classes.Text>訊號提示聲</classes.Text>

      <classes.ButtonGroup>
        <classes.Button onClick={event => {
            useSoundStore.setState({ soundsEnabled: true });
        }} className={clas([
            props.className,
            classes.ButtonOfStart,
            isActive && classes.ButtonOfStartOfActive,
        ])}>
          開啟
        </classes.Button>

        <classes.Button onClick={event => {
            useSoundStore.setState({ soundsEnabled: false });
        }} className={clas([
            props.className,
            classes.ButtonOfEnd,
            !isActive && classes.ButtonOfEndOfActive,
        ])}>
          關閉
        </classes.Button>
      </classes.ButtonGroup>
    </classes.Root>);
});
export default {
    Display: SoundSwitch,
    classes,
};
