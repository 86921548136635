import styled from '@emotion/styled';
import { fontWeight400, fontWeight600 } from '~/css/font';
import { flex } from '~/modules/AppLayout/FlexGridCss';
export const SymbolBricks = styled.div `
  ${flex.v.allCenter};
  width: 160px;
  height: 100%;
  font-size: 14px;
  border-radius: 5px;
  background-color: #222222;
  border: 1px solid ${s => (s.active === true ? '#eeeeee' : 'transparent')};
  ${fontWeight600};
  transition: 0.1s;
  &:hover {
    border: 1px solid #888888;
  }
`;
export const StockNameArea = styled.div `
  ${flex.h.allCenter};
  width: 100%;
  height: 35%;
  border-radius: 5px 5px 0px 0px;
  font-size: 14px;
  color: #ffffff;
  background-color: ${props => props.color};
`;
export const MidQuoteArea = styled.div `
  ${flex.inline.crossCenter};
  width: 100%;
  height: 40%;
  font-size: 16px;
  ${fontWeight400};
`;
export const BottomQuoteArea = styled.div `
  ${flex.inline.crossCenter};
  width: 100%;
  height: 25%;
  font-size: 12px;
  ${fontWeight400};
`;
export const QuoteNow = styled.div `
  ${flex.h.allCenter};
  width: 100%;
  height: 100%;
`;
export const QuoteChange = styled.div `
  ${flex.inline.allCenter};
  width: 50%;
  height: 100%;
`;
export const QuoteChangePer = styled.div `
  ${flex.inline.allCenter};
  width: 50%;
  height: 100%;
`;
