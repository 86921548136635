import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { memo } from 'react';
import { flex } from '~/modules/AppLayout/FlexGridCss';
import { SignalrSymbolSwitchBrick } from '~/modules/investment-consultant/siganlarQuote/SignalrSymbolSwitchBrick';
import { SymbolBricks } from '~/modules/investment-consultant/siganlarQuote/SignalrSymbolSwitchBrickCss';
import { useZScorePick } from '~/modules/investment-consultant/stockPickTable/stockPickTable';
import { store } from '~/pages/heineken_template/_private/store';
const classes = {
    Root: styled.div ``,
    Item: SymbolBricks,
};
const cssset = css `
  &${classes.Root} {
    ${flex.h.allCenter};
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
    gap: 4px;

    ${classes.Item} {
      cursor: pointer;
      width: calc(50% - 2px);
    }
  }
`;
export const Display = memo(function Display(props) {
    let { resultSymbol: symbols } = useZScorePick(props.useZScorePickProps);
    symbols = symbols?.slice(0, props.max ?? 10000);
    return (<classes.Root className={props.className} css={cssset}>
      {symbols?.map((symbol, index) => {
            return (<SignalrSymbolSwitchBrick key={index} onClick={event => {
                    props.onClick?.(symbol);
                    store.charting.changeSymbol(symbol);
                    store.charting.updateFromState();
                }} symbol={symbol}/>);
        })}
    </classes.Root>);
});
/**
 * # ZScore 磚牆
 *
 * ## e.g. `短線強勢股` `短線弱勢股` `波段走多股` `波段走空股`
 */
export default {
    Display,
    classes,
};
