import Cookie from 'cookie-universal';
import { forEach } from 'lodash';
/**
 * 雖然官方有提供 `$chatwoot.reset()` 來讓使用者在登出期天時，可以尻它
 *
 * 但這樣會使對話也被刷新，而無法保持「繼續對話」特性
 *
 * 我們是想要它，無論登出登入怎麼切換 uid，反正只要是同一個 uid 都要保持「繼續對話」功能
 *
 * 所以我們選擇在 chatwoot SDK 加載前，手工清除 cookies
 *
 * 讓其在後續依照 setUser(uid) 抓出「繼續對話」的歷史訊息出來即可
 */
export const _initializeCookies = () => {
    const cookies = Cookie();
    forEach(cookies.getAll(), (cookieValue, key) => {
        if (key.includes('cw_user_')) {
            cookies.remove(key);
        }
    });
    cookies.remove('_chatwoot_session');
    cookies.remove('cw_conversation');
};
