import { proxy } from 'valtio';
/**
 * using valtio
 */
export const notificationProxy = proxy({
    isOpen: false,
    message: '',
    msLevel: 'info',
    duration: 5000,
});
