/* eslint-disable react/display-name */
import { DatePickerInput } from '@mantine/dates';
import { TextField } from '@mui/material';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers';
import { isDate, isString } from 'lodash';
import { memo } from 'react';
import { proxy, ref, useSnapshot } from 'valtio';
import { component } from '~/utils/component';
import dayAPI from '~/utils/dayAPI';
export class FuiDatePicker {
    store;
    constructor() {
        this.store = proxy({
            date: dayAPI(),
        });
    }
    setDate(date) {
        this.store.date = isDate(date) ? dayAPI(date) : isString(date) ? dayAPI(date) : date;
    }
    formatBy(
    /**
     * - 若給 `/` 則返回 `2022/12/22`（預設）
     * - 若給 `-` 則返回 `2022-12-22`
     */
    divider) {
        divider = divider || `/`;
        return this.store.date.format(`YYYY${divider}MM${divider}DD`);
    }
    DatePickerOfMt = ref(component(props => {
        const state = useSnapshot(this.store);
        const minDate = props.minDate || dayAPI().subtract(30, 'day');
        const maxDate = props.maxDate || dayAPI();
        return (<DatePickerInput className={props.className} locale='zh-tw' closeOnChange valueFormat='YYYY/MM/DD' defaultValue={state.date.toDate()} onChange={value => {
                value && this.setDate(value);
            }} minDate={minDate.toDate()} maxDate={maxDate.toDate()}></DatePickerInput>);
    }));
    DatePickerOfMui = ref(memo(props => {
        const state = useSnapshot(this.store);
        const minDate = props.minDate || dayAPI().subtract(30, 'day');
        const maxDate = props.maxDate || dayAPI();
        return (<MuiDatePicker className={props.className} {...{
            disableToolbar: true,
            fullWidth: true,
            variant: 'inline',
            inputVariant: 'outlined',
            size: 'small',
            format: 'YYYY/MM/DD',
            InputProps: {
                style: {
                    height: 30,
                },
            },
        }} renderInput={params => <TextField {...params}/>} value={state.date} onChange={(value) => {
                if (value) {
                    this.store.date = value;
                }
            }} minDate={minDate} maxDate={maxDate}/>);
    }));
}
