/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useMount } from 'react-use';
import { useProxy } from 'valtio/utils';
import { AgentProduct } from '~/configs/AgentProduct';
import { _Widget } from '~/modules/SDK/chatwoot/_Widget';
import { fr_me } from '~/pages/heineken_template/_fr/fr_me';
import { component } from '~/utils/component';
import hmacSHA256 from 'crypto-js/hmac-sha256';
import { _initializeCookies } from '~/modules/SDK/chatwoot/_initializeCookies';
const chatwootConfig = {
    [AgentProduct['futuresai@web']]: {
        websiteToken: '4iy8zFB69AWfNfGUyUFMZ82L',
        identityKey: 'XMSuY4JY5ouL2uNVnSDdTHu3',
    },
};
export const chatwoot = {
    config: chatwootConfig,
    initializeCookies: _initializeCookies,
    Widget: component(props => {
        useProxy(fr_me);
        const [chatwootReady, setChatwootReady] = useState(false);
        const onChatwootMessageFn = function (e) {
            console.log('chatwoot:on-message', e.detail.sender.email, e.detail);
        };
        useMount(() => {
            globalThis.window.addEventListener('chatwoot:ready', function () {
                console.log('chatwoot:ready', fr_me.me);
                setChatwootReady(true);
            });
            globalThis.window.addEventListener('chatwoot:error', function (error) {
                console.log('run chatwoot:error', error);
            });
        });
        useEffect(() => {
            if (!chatwootReady)
                return;
            if (!fr_me.me.email)
                return;
            if (!fr_me.me.displayName)
                return;
            if (!fr_me.me.uid)
                return;
            const hmacDigest = hmacSHA256(fr_me.me.uid, props.config.identityKey).toString();
            console.log('$chatwoot.setUser', fr_me.me.email);
            globalThis.window.$chatwoot.setUser(fr_me.me.uid, {
                name: fr_me.me.displayName,
                email: fr_me.me.email,
                identifier_hash: hmacDigest,
            });
            // first close then open, it will start conversation automatically
            globalThis.window.$chatwoot.toggle('close');
            setTimeout(globalThis.window.$chatwoot.toggle, 1000, 'open');
            globalThis.window.addEventListener('chatwoot:on-message', onChatwootMessageFn);
            return () => {
                globalThis.window.removeEventListener('chatwoot:on-message', onChatwootMessageFn);
            };
        }, [
            chatwootReady,
            props.config.identityKey,
            fr_me.me.uid,
            fr_me.me.email,
            fr_me.me.displayName,
        ]);
        return <_Widget token={props.config.websiteToken}/>;
    }),
};
