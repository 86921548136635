import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { memo, useEffect } from 'react';
import { useSnapshot } from 'valtio';
import { useThemeStore } from '~/components/theme/useThemeStore';
import { fontWeight500 } from '~/css/font';
import { fill, fill_horizontal_all_center, fill_vertical_all_center, pureGrid, } from '~/modules/AppLayout/FlexGridCss';
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2';
import { useSignalrStoreValueOHLC } from '~/modules/SDK/Signalr/useSignalrStoreValueOHLC';
import { SymbolName } from '~/modules/SDK/Symbol/SymbolName';
import { useStockToFutureMapper } from '~/modules/SDK/Symbol/useCommonSymbolList';
import QuoteValueItem from '../../quoteValueBody/QuoteValueBody';
import { quoteBasicStyled } from '../css/quoteBasicStyled';
/** 會顯示當日交易的方向 bs: 多/空 match: TransactionAPI */
const SymbolCardTransactionBody = memo(function SymbolCardTransactionBody(props) {
    const futuresDisctionary = useStockToFutureMapper();
    const stateAll = props.bs === 'All';
    const stateLong = props.bs === 'B' || stateAll ? classesFill.upFill : classesFill.defaultFill;
    const stateShort = props.bs === 'S' || stateAll ? classesFill.dnFill : classesFill.defaultFill;
    const isAvtive = props.symbol === props.currentSymbol ? '-active' : '';
    const symbolInfo = useSignalrStoreValueOHLC(state => state.value[props.symbol]);
    useEffect(() => {
        signalrStore2.addQuote(props.symbol);
        return () => {
            signalrStore2.removeQuote(props.symbol);
        };
    }, [props.symbol]);
    const quote = useSnapshot(signalrStore2.values.quote)[props.symbol];
    const theme = useThemeStore(s => s.theme);
    const limitUp = symbolInfo?.limitUpPrice !== undefined && symbolInfo?.limitUpPrice === symbolInfo?.close;
    const limitDn = symbolInfo?.limitDownPrice !== undefined && symbolInfo?.limitDownPrice === symbolInfo?.close;
    const limitFill = limitUp ? classesFill.upFill : limitDn ? classesFill.dnFill : '';
    return (<classes.Root css={baseStyled}>
      <classes.container className={theme + isAvtive}>
        <classes.positionContainer>
          <classes.positionLong css={stateLong} className={theme}>
            多
          </classes.positionLong>
          <classes.positionShort className={theme} css={stateShort}>
            空
          </classes.positionShort>
        </classes.positionContainer>

        <classes.disctionary>{futuresDisctionary?.[props.symbol] ? '★' : ''}</classes.disctionary>

        <classes.name className={theme} css={limitFill}>
          <SymbolName symbol={props.symbol}/>
          <div>{props.symbol}</div>
        </classes.name>

        <classes.quoteValue>
          <QuoteValueItem.ohlc.Display quote={quote} ohlcType='close'/>
        </classes.quoteValue>

        <classes.quoteValue>
          <QuoteValueItem.change.Display quote={quote} changeType='closeChange'/>
        </classes.quoteValue>

        <classes.quoteValue>
          <QuoteValueItem.change.Display quote={quote} changeType='closeChangePercent' unit='%'/>
        </classes.quoteValue>

        <classes.qouteVolume>
          <QuoteValueItem.ohlc.Display quote={quote} ohlcType='volume'/>
        </classes.qouteVolume>
      </classes.container>
    </classes.Root>);
});
const classes = {
    Root: styled.div `
    ${fill}
  `,
    container: styled.div `
    ${fill_horizontal_all_center};
    ${pureGrid};
    grid-template-columns: 15% 6% 13% 18% 15% 15% 18%;
    width: 100%;
    height: 40px;
    border-radius: 5px;
    font-size: 14px;
    ${fontWeight500};
    padding: 2px;
    cursor: pointer;
    transition: 0.3s;
  `,
    name: styled.div `
    ${fill_vertical_all_center}
    width: 100%;
    font-size: 12px;
    background-color: ${props => props.limitPriceFill};
    border-radius: 4px;
    white-space: nowrap;
    text-overflow: ellipsis;
  `,
    positionShort: styled.div `
    ${fill_horizontal_all_center};
    width: 24px;
    height: 24px;
    border-radius: 4px;
    font-size: 12px;
  `,
    positionLong: styled.div `
    ${fill_horizontal_all_center};
    width: 24px;
    height: 24px;
    border-radius: 4px;
    font-size: 12px;
  `,
    quoteValue: styled.div `
    ${fill_horizontal_all_center};
  `,
    qouteVolume: styled.div `
    ${fill_horizontal_all_center};
    justify-content: end;
  `,
    positionContainer: styled.div `
    ${fill_horizontal_all_center}
    width:100%;
    gap: 5px;
  `,
    disctionary: styled.div `
    ${fill_horizontal_all_center}
    justify-content: end;
    width: 100%;
    font-size: 8px;
  `,
};
const classesFill = {
    defaultFill: css `
    &.dark {
      background-color: #2d2d2d;
    }
    &.light {
      background-color: #aaaaaa;
    }
  `,
    upFill: css `
    &.dark {
      background-color: #fb0000;
    }
    &.light {
      background-color: #fb0000;
    }
  `,
    dnFill: css `
    &.dark {
      background-color: #00aa00;
    }
    &.light {
      background-color: #009900;
    }
  `,
};
const baseStyled = css `
  ${classes.Root} {
  }
  ${classes.container} {
    ${quoteBasicStyled.containerWithBorder}
  }
  ${classes.positionLong} {
    color: #ffffff;
  }
  ${classes.positionShort} {
    color: #ffffff;
  }
  ${classes.disctionary} {
    color: #ffaa00;
  }
`;
export default {
    Display: SymbolCardTransactionBody,
    classes,
    classesFill,
};
