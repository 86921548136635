import React from 'react';
import { _initializeCookies } from '~/modules/SDK/chatwoot/_initializeCookies';
export class _Widget extends React.PureComponent {
    componentDidMount() {
        const token = this.props.token;
        _initializeCookies();
        // Add Chatwoot Settings
        window.chatwootSettings = {
            hideMessageBubble: false,
            position: 'right',
            locale: 'zh',
            type: 'standard',
        };
        (function (d, t) {
            const BASE_URL = 'https://app.chatwoot.com';
            const g = d.createElement(t);
            const s = d.getElementsByTagName(t)[0];
            g.src = BASE_URL + '/packs/js/sdk.js';
            g.defer = true;
            g.async = true;
            s.parentNode?.insertBefore(g, s);
            g.onload = function () {
                window.chatwootSDK.run({
                    websiteToken: token,
                    baseUrl: BASE_URL,
                });
            };
        })(document, 'script');
    }
    render() {
        return null;
    }
}
