import { css } from '@emotion/react';
import React, { memo } from 'react';
import { useMount, useUnmount } from 'react-use';
import { useSignalrStore } from '~/modules/SDK/Signalr/useSignalrStore';
import { processQuoteToReadable } from '~/utils/processQuoteToReadable';
import { SymbolBricks, StockNameArea, MidQuoteArea, BottomQuoteArea, QuoteChange, QuoteChangePer, QuoteNow, } from '~/modules/investment-consultant/siganlarQuote/SignalrSymbolSwitchBrickCss';
import { useSignalrStoreValueOHLC } from '~/modules/SDK/Signalr/useSignalrStoreValueOHLC';
import { SymbolName } from '~/modules/SDK/Symbol/SymbolName';
import { store } from '~/pages/heineken_template/_private/store';
import { useSnapshot } from 'valtio';
export const SignalrSymbolSwitchBrick = memo(function SignalrSymbolSwitchBrick(props) {
    const symbolInfo = useSignalrStoreValueOHLC(state => state.value[props.symbol]);
    const quoteChanges = processQuoteToReadable(symbolInfo);
    const changeColor = quoteChanges.closeChange >= 0 ? '#fb0000' : '#01c200';
    const changeArrow = quoteChanges.closeChange >= 0 ? '▲' : '▼';
    const limitUp = quoteChanges.closeChangePercent >= 9;
    const limitDn = quoteChanges.closeChangePercent <= -9;
    const limitPercent = limitUp || limitDn;
    const limitTextColor = limitPercent ? '#ffffff' : '#111111';
    const limitColor = limitUp ? '#bb0000' : limitDn ? '#01b200' : '#333333';
    const symbol = useSnapshot(store.charting).symbol;
    const active = symbol === props.symbol;
    useMount(() => {
        useSignalrStore.getState().subscribeAdd([props.symbol], 'ohlc');
    });
    useUnmount(() => {
        useSignalrStore.getState().subscribeRemove([props.symbol], 'ohlc');
    });
    return (<SymbolBricks active={active} onClick={event => {
            props.onClick?.(props.symbol);
        }}>
      <StockNameArea color={limitColor}>
        <SymbolName symbol={props.symbol}/>
        &nbsp;
        {props.symbol}
      </StockNameArea>
      <MidQuoteArea>
        <QuoteNow css={css `
            color: ${changeColor};
          `}>
          {changeArrow}
          &nbsp;
          {symbolInfo?.close ?? '-'}
        </QuoteNow>
      </MidQuoteArea>
      <BottomQuoteArea>
        <QuoteChange css={css `
            color: ${changeColor};
          `}>
          {symbolInfo ? (quoteChanges.closeChange >= 0 ? '+' : '') : ''}
          {symbolInfo ? quoteChanges.closeChange : '-'}
        </QuoteChange>
        <QuoteChangePer css={css `
            color: ${changeColor};
          `}>
          {symbolInfo ? quoteChanges.closeChangePercentString : '-'}
        </QuoteChangePer>
      </BottomQuoteArea>
    </SymbolBricks>);
});
