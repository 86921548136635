import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { memo } from 'react';
import { AppLink } from '~/components/AppLink';
import { fr_agents } from '~/pages/heineken_template/_fr/fr_agents';
import { flex } from '~/modules/AppLayout/FlexGridCss';
import AgentFavicon from '~/pages/heineken_template/components/AgentFavicon';
import TemplateTheme from '~/pages/heineken_template/_private/TemplateTheme';
const classes = {
    Title: styled.div `
    font-size: 24px;
    font-weight: bold;
  `,
};
const cssset = css `
  ${classes.Title} {
    font-size: 24px;
    font-weight: bold;
  }

  ${TemplateTheme.classes.Dark} & {
    ${classes.Title} {
      color: white;
    }
  }

  ${TemplateTheme.classes.Light} & {
    ${classes.Title} {
      color: black;
    }
  }
`;
/** # Agent Favicon 配上大大的 Agent Title */
const Col_FaviconTitle = memo(function FaviconTitle(props) {
    return (<AppLink href={`/heineken_template`} css={css([
            cssset,
            css `
          ${flex.h.allCenter};
        `,
        ])}>
      <AgentFavicon.Display />
      <classes.Title>{fr_agents.config.title}</classes.Title>
    </AppLink>);
});
export default {
    Display: Col_FaviconTitle,
};
